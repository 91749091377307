import { useForm } from "@mantine/form";
import {
  Button as MantineButton,
  Group,
  TextInput,
  Select,
  Loader,
} from "@mantine/core";
import { CalendarEvent, ChevronLeft } from "tabler-icons-react";
import { Button } from "../../shared/button";
import { DatePickerInput } from "@mantine/dates";
import {
  CollectionFormValues,
  UpdateCollection,
} from "../../lib/api/types/collection";
import {
  addCollection,
  getCollectionById,
  updateCollection,
} from "../../lib/api/collections";
import { useEffect, useState } from "react";
import ProductsTable from "./productsTable";

interface CollectionFormProps {
  title: string;
  id: string | null;
  close: () => void;
}

const CollectionForm: React.FC<CollectionFormProps> = ({
  title,
  close,
  id,
}) => {
  const [loading, setLoading] = useState(true);
  const form = useForm<CollectionFormValues>({
    initialValues: {
      id: "",
      publish_from: "",
      publish_to: "",
      name: "",
      active: true,
      product_ids: [""],
    },
    validate: {
      name: (value) => (value ? null : "Имя коллекции обязательно"),
      active: (value) => (value ? null : "Статус активности обязателен"),
    },
  });

  const [collectionProduct, setCollectionProduct] = useState<string[] | null>(null);
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([new Date(), new Date()]);
  useEffect(() => {
    const fetchCollection = async () => {
      if (id) {
        setLoading(true);
        try {
          const collection = await getCollectionById(id);
          setDateValue([
            new Date(collection.publish_from),
            new Date(collection.publish_to),
          ]);
          setCollectionProduct(
            collection.product_ids ? collection.product_ids : null
          );
          form.setValues({
            id: collection.id,
            name: collection.name,
            active: collection.active,
          });
        } catch (error) {
          console.error("Failed to fetch banner:", error);
        } finally {
          setLoading(false);
        }
      } else {
        form.reset();
        setLoading(false);
      }
    };
    fetchCollection();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.reset();
    close();
  };

  const handleCollection = async (values: CollectionFormValues) => {
    const formattedValues: UpdateCollection = {
      active: values.active,
      name: values.name,
      publish_from: dateValue[0]?.toISOString().slice(0, -5) + "Z",
      publish_to: dateValue[1]?.toISOString().slice(0, -5) + "Z",
      product_ids: collectionProduct ? collectionProduct : null,
    };
    try {
      if (values.id) {
        await updateCollection(values.id, formattedValues);
      } else {
        await addCollection(formattedValues);
      }
      form.reset();
      close();
    } catch (error) {
      console.error("Failed to save collection:", error);
    }
  };
  const handleProduct = (productId: string, type: string) => {
    if (type === "select") {
      setCollectionProduct((prevProducts) => {
        const products = prevProducts || [];
        return [...products, productId];
      });
    } else if (type === "selected") {
      setCollectionProduct((prevProducts) => {
        const products = prevProducts || [];
        return products.filter((id) => id !== productId);
      });
    }
  };

  const headers = [
    "ID товара на сайте",
    "Наименование товара",
    "Характеристики",
    "Действие",
  ];

  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не активен";
  return (
    <div>
      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <MantineButton
            leftSection={<ChevronLeft size={20} />}
            onClick={handleCancel}
            style={{
              backgroundColor: "#ffffff",
              color: "#25262B",
              fontSize: "16px",
              padding: 0,
              border: "#ffffff",
              transition: "background-color 0.3s",
              marginBottom: "2%",
            }}
          >
            Назад
          </MantineButton>
          <div className="flex flex-col gap-6">
            <Group
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h1 className="basefont-h1">{title}</h1>
              <Group>
                {title === "Редактирование подборки" && (
                  <Button
                    type="button"
                    onClick={handleCancel}
                    style={{
                      backgroundColor: "#ffffff",
                      color: "#1E2134",
                      width: "200px",
                    }}
                  >
                    Отменить
                  </Button>
                )}

                <Button
                  type="button"
                  onClick={() => form.onSubmit(handleCollection)()}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "200px",
                  }}
                >
                  Сохранить
                </Button>
              </Group>
            </Group>
            {title === "Создание подборки" ? (
              <>
                <Group>
                  <TextInput
                    style={{ flex: 1 }}
                    label="Название"
                    placeholder="Введите название"
                    {...form.getInputProps("name")}
                    size="md"
                    radius="md"
                  />
                  <DatePickerInput
                    style={{ flex: 1 }}
                    valueFormat="DD.MM.YYYY"
                    rightSection={
                      <CalendarEvent
                        size={20}
                        color="#506176"
                        strokeWidth={1.5}
                      />
                    }
                    label="Период действия"
                    type="range"
                    placeholder="Выберите период действия"
                    size="md"
                    radius="md"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                    }}
                  />
                </Group>
                <Group>
                  <Select
                    style={{ flex: 0.5 }}
                    label="Статус активности"
                    {...form.getInputProps("name")}
                    placeholder="Выберите статус"
                    data={["Активен", "Не активен"]}
                    value={getStatusLabel(form.values.active)}
                    onChange={(value) =>
                      form.setFieldValue("active", value === "Активен")
                    }
                    size="md"
                    radius="md"
                  />
                </Group>
              </>
            ) : (
              <>
                <Group>
                  <TextInput
                    style={{ flex: 1 }}
                    label="Название"
                    placeholder="Введите название"
                    {...form.getInputProps("name")}
                    size="md"
                    radius="md"
                  />
                  <TextInput
                    style={{ flex: 1 }}
                    label="Ссылка на подборку"
                    placeholder="Введите ссылку"
                    value={form.values.url}
                    disabled
                    size="md"
                    radius="md"
                  />
                </Group>
                <Group>
                  <DatePickerInput
                    style={{ flex: 1 }}
                    valueFormat="DD.MM.YYYY"
                    rightSection={
                      <CalendarEvent
                        size={20}
                        color="#506176"
                        strokeWidth={1.5}
                      />
                    }
                    label="Период действия"
                    type="range"
                    placeholder="Выберите период действия"
                    size="md"
                    radius="md"
                    value={dateValue}
                    onChange={(newValue) => {
                      setDateValue(newValue);
                      if (newValue[0])
                        form.setFieldValue("publish_from", newValue[0]);
                      if (newValue[1])
                        form.setFieldValue("publish_to", newValue[1]);
                    }}
                  />
                  <Select
                    style={{ flex: 1 }}
                    label="Статус активности"
                    placeholder="Выберите статус"
                    data={["Активен", "Не активен"]}
                    value={getStatusLabel(form.values.active)}
                    onChange={(value) =>
                      form.setFieldValue("active", value === "Активен")
                    }
                    size="md"
                    radius="md"
                  />
                </Group>
                <TextInput
                  style={{ flex: 0.5, width: "50%" }}
                  label="ID"
                  placeholder="ID"
                  {...form.getInputProps("id")}
                  disabled
                  size="md"
                  radius="md"
                />
              </>
            )}
            <Group>
              <h2 className="basefont-h2">Добавленные товары</h2>
              <ProductsTable
                headers={headers}
                productsIds={collectionProduct}
                type="selected"
                handleProduct={handleProduct}
              />
            </Group>

            <Group>
              <h2 className="basefont-h2">Выбор товаров</h2>
              <ProductsTable
                headers={headers}
                productsIds={collectionProduct}
                type="select"
                handleProduct={handleProduct}
              />
            </Group>
          </div>
        </>
      )}
    </div>
  );
};

export default CollectionForm;
