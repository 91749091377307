import { useEffect, useState } from "react";
import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import TablePattern from "./table-pattern";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import Paginator from "../shared/paginator";
import { GetProductErrorParams, productError } from "../lib/api/types/productsError";
import { getProductsError } from "../lib/api/productError";

const ErrorProductsTable = ({ openedSideBar }: { openedSideBar: boolean }) => {
  const headers = [
    "ID товара на сайте",
    "Дата создания",
    "Наименование товара",
  ];
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);
  const fetchProducts = async (params: GetProductErrorParams) => {
    setLoading(true);
    const response = await getProductsError(params);
    setData(response.product_errors);
    setTotal(response.total);
    setLoading(false);
  };

  const [data, setData] = useState<productError[]>([]);

  useEffect(() => {
    const offset = (activePage - 1) * selectedRowsNumber;
    const params: GetProductErrorParams = {
      search: searchId,
      created_from: dateValue[0]?.toISOString() || null,
      created_to: dateValue[1]?.toISOString() || null,
      offset: offset,
      limit: selectedRowsNumber,
    };
    fetchProducts(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, dateValue, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
  };


  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 4, width: "704px", marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: openedSideBar ? `calc(100% - 5px)` : '',
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        type="range"
        label="Дата создания"
        placeholder="Любая"
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const formattedProductErrors = data.map((error: productError) => ({
    ...error,
    created: new Date(error.created).toLocaleDateString("ru-RU"),
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={formattedProductErrors}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default ErrorProductsTable;
