import { Button, Group, Select, Card, Loader } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChevronLeft } from "tabler-icons-react";
import { useEffect, useState } from "react";
import { getReview, updateReview } from "../../lib/api/review";
import { Button as CustomButton } from "../../shared/button";
import { Review, ReviewDetail } from "../../lib/api/types/review";

interface EditReviewProps {
  editReview: (value: boolean, review: Review | null) => void;
  data: { item_id: string; user_id: string } | null;
}

const EditReview: React.FC<EditReviewProps> = (props) => {
  const [loading, setLoading] = useState(true);

  const form = useForm<ReviewDetail>({
    initialValues: {
      user: {
        id: "",
        phone: "",
        email: "",
        first_name: "",
        last_name: "",
        father_name: "",
      },
      item_id: "",
      created: "",
      status: "",
      rating: 0,
      comment: "",
      positive: "",
      negative: "",
    },
  });

  useEffect(() => {
    const fetchReviewDetail = async () => {
      if (props.data) {
        try {
          const review = await getReview(
            props.data.item_id,
            props.data.user_id
          );
          form.setValues({
            ...review,
            user: {
              ...review.user,
            },
            item_id: review.item_id,
            created: new Date(review.created).toLocaleDateString("ru-RU"),
            status: review.status,
            rating: review.rating,
            comment: review.comment,
            positive: review.positive,
            negative: review.negative,
          });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching review:", error);
        }
      }
    };
    fetchReviewDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancel = () => {
    form.reset();
    props.editReview(false, null);
  };

  const handleSubmit = async (values: ReviewDetail) => {
    if (props.data) {
      try {
        await updateReview(
          props.data.item_id,
          props.data.user_id,
          values.status
        );
        props.editReview(false, null);
      } catch (error) {
        console.error("Error updating review:", error);
      }
    }
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <>
      <Button
        className="back-button"
        leftSection={<ChevronLeft size={20} />}
        onClick={handleCancel}
        style={{
          backgroundColor: "#ffffff",
          color: "#25262B",
          fontSize: "16px",
          padding: 0,
          border: "#ffffff",
          transition: "background-color 0.3s",
          marginBottom: "2%",
        }}
      >
        Назад
      </Button>

      <div className="flex flex-col gap-6">
        <Group
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2%",
          }}
        >
          <h1 className="basefont-h1">Отзыв {form.values.item_id}</h1>
          <CustomButton
            onClick={() => form.onSubmit(handleSubmit)()}
            style={{
              color: "#ffffff",
              backgroundColor: "#0560BE",
              width: "200px",
            }}
          >
            Сохранить
          </CustomButton>
        </Group>

        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 space-y-4">
            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <h2 className="basefont-h2">Отзыв</h2>
              <div className="mb-4">
                <div className="font-medium text-md">Общие впечатления</div>
                <span>{form.values.comment}</span>
              </div>
              <div className="mb-4">
                <div className="font-medium text-md">Достоинства</div>
                <span>{form.values.positive}</span>
              </div>
              <div>
                <div className="font-medium text-md">Недостатки</div>
                <span>{form.values.negative}</span>
              </div>
            </Card>

            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <h2 className="basefont-h2">Информация о клиенте</h2>
              <div className="mb-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="font-medium text-md">ID клиента</div>
                    <span>{form.values.user.id}</span>
                  </div>
                  <div>
                    <div className="font-medium text-md">ФИО</div>
                    <span>{`${form.values.user.first_name} ${form.values.user.last_name} ${form.values.user.father_name}`}</span>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="font-medium text-md">Номер телефона</div>
                    <span>{form.values.user.phone}</span>
                  </div>
                  <div>
                    <div className="font-medium text-md">Почта</div>
                    <span>{form.values.user.email}</span>
                  </div>
                </div>
              </div>
            </Card>

            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <h2 className="basefont-h2">Общая информация</h2>
              <div className="mb-4">
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <div className="font-medium text-md">ID отзыва</div>
                    <span>{form.values.item_id}</span>
                  </div>
                  <div>
                    <div className="font-medium text-md">Дата создания</div>
                    <span>{form.values.created}</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="font-medium text-md">ID товара</div>
                <span>{form.values.item_id}</span>
              </div>
            </Card>
          </div>

          <div className="space-y-4">
            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <h2 className="basefont-h2">Статус модерации</h2>
              <Select
                label="Статус активности"
                placeholder="Выберите статус"
                data={[
                  { value: "approved", label: "Подтвержден" },
                  { value: "rejected", label: "Отклонен" },
                  { value: "pending", label: "Ожидает подтверждения" },
                ]}
                styles={{
                  input: {
                    borderRadius: "12px",
                  },
                }}
                {...form.getInputProps("status")}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditReview;
