import React, { useEffect, useState } from "react";
import { useForm } from "@mantine/form";
import { MantineProvider, TextInput } from "@mantine/core";
import { Eye, EyeOff } from "tabler-icons-react";
import "./login.css";
import { Button } from "../../shared/button";
import { login } from "../../lib/api/auth";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const form = useForm({
    initialValues: {
      email: "",
      password: "",
    },
    validate: {
      email: (value) =>
        /^\S+@\S+\.\S+$/.test(value) ? null : "Введите корректный email",
      password: (value) => (value ? null : "Пароль обязателен"),
    },
  });


  useEffect(() => {
    const accessToken = localStorage.getItem("access_token");
    const refreshToken = localStorage.getItem("refresh_token");

    if (accessToken && refreshToken) {
      navigate("/main");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: { email: string; password: string }) => {
    try {
      setErrorMessage(null); // Clear any previous errors
      const result = await login(values.email, values.password);
      localStorage.setItem("access_token", result.access_token);
      localStorage.setItem("refresh_token", result.refresh_token);
      navigate("/main");
    } catch (error) {
      console.error("Login failed:", error);
      setErrorMessage("Неверный логин или пароль"); // Set the error message
    }
  };

  return (
    <MantineProvider>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <div
          style={{
            margin: "0 auto",
            textAlign: "center",
            width: "464px",
            height: "332px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          className="card border border-stroke-light rounded-xl px-[32px] py-[24px] flex flex-col gap-4"
        >
          <span className="basefont-h2 text-left">Вход в систему</span>
          <TextInput
            label="Логин"
            {...form.getInputProps("email")}
            placeholder="Введите логин"
            value={form.values.email}
            onChange={(event) =>
              form.setFieldValue("email", event.currentTarget.value)
            }
            styles={{
              label: {
                textAlign: "left",
                fontWeight: "400",
                display: "block",
                marginBottom: "4px",
              },
            }}
          />
          <TextInput
            label="Пароль"
            {...form.getInputProps("password")}
            placeholder="Введите пароль"
            type={showPassword ? "text" : "password"}
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue("password", event.currentTarget.value)
            }
            rightSection={
              <div
                style={{ cursor: "pointer" }}
                onClick={() => setShowPassword((prev) => !prev)}
              >
                {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
              </div>
            }
            styles={{
              label: {
                textAlign: "left",
                fontWeight: "400",
                display: "block",
                marginBottom: "4px",
              },
            }}
          />
          {errorMessage && (
            <div style={{ color: "red", marginTop: "10px" }}>
              {errorMessage}
            </div>
          )}
          <Button
            type="submit"
            className="w-[400px] h-[48px] mt-[10px] mx-auto"
          >
            ВОЙТИ
          </Button>
        </div>
      </form>
    </MantineProvider>
  );
}

export default Login;
