import { Button, Group, TextInput, Card, Loader, Select, Text } from "@mantine/core";
import { useForm } from "@mantine/form";
import { ChevronLeft } from "tabler-icons-react";
import { useEffect, useState } from "react";
import {
  createArticle,
  getArticleById,
  getCategories,
  updateArticle,
  uploadArticleBodyImage,
} from "../../lib/api/articles";
import { Button as CustomButton } from "../../shared/button";
import {
  Article,
  ArticleFormData,
  Category,
  CreateArticleData,
} from "../../lib/api/types/articles";
import { RTE } from "../../shared/richTextEditor";
import { useDebouncedValue } from "@mantine/hooks";
import { Dropzone, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import { IconTrash } from "@tabler/icons-react";

interface EditArticleProps {
  title: string;
  editArticle: (value: boolean, review: Article | null) => void;
  id: string | null;
}

interface UploadedImage {
  file?: File;
  url: string | null;
}

const EditArticle: React.FC<EditArticleProps> = (props) => {
  const [loading, setLoading] = useState(true);
  const [rteContent, setRteContent] = useState("");
  const [categories, setCategories] = useState<Category[]>([]);
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [initialFetch, setInitialFetch] = useState(false);
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 300);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(null);
  const form = useForm<ArticleFormData>({
    initialValues: {
      active: false,
      collection_id: "",
      image_url: "",
      subtitle: "",
      title: "",
      body: "",
      category: {
        id: "",
        name: "",
      },
    },
    validate: {
      active: (value) => (typeof value === "boolean" ? null : "Это поле обязательно"),
      collection_id: (value) => (value ? null : "ID коллекции обязателен"),
      subtitle: (value) => (value ? null : "Подзаголовок обязателен"),
      title: (value) => (value ? null : "Заголовок обязателен"),
    },
  });


  useEffect(() => {
    const fetchArticleDetail = async () => {
      if (props.id) {
        try {
          const article = await getArticleById(props.id);
          form.setValues({
            active: article.active,
            collection_id: article.collection_id,
            title: article.title,
            subtitle: article.subtitle,
            image_url: article.image_url,
            body: article.body,
            category: { id: article.category.id, name: article.category.name },
          });
          if (article.image_url) { setUploadedImage({ url: article.image_url }) }
          setRteContent(article.body);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching article:", error);
        }
      } else {
        setLoading(false);
        form.reset();
      }
    };
    fetchArticleDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  useEffect(() => {
    const fetchCategories = async () => {
      if (!initialFetch || (debouncedSearchTerm === "" && !menuVisible)) {
        return;
      }

      setFetching(true);
      try {
        const response = await getCategories({
          debouncedSearchTerm,
          offset: 0,
          limit: 10,
        });
        setCategories(response.data.categories);
        setTotal(response.data.total);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setFetching(false);
        setLimit(10);
      }
    };

    fetchCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, initialFetch]);

  const handleScroll = async (e: React.UIEvent<HTMLDivElement>) => {
    const bottom =
      e.currentTarget.scrollHeight - e.currentTarget.scrollTop ===
      e.currentTarget.clientHeight;
    if (bottom && limit < total && !fetching) {
      setFetching(true);
      try {
        setLimit(limit + 10);
        const response = await getCategories({
          debouncedSearchTerm,
          offset: 0,
          limit: limit,
        });

        setCategories((prevCategories) => [
          ...prevCategories,
          ...response.data.categories,
        ]);
      } catch (error) {
        console.error("Error fetching more categories:", error);
      } finally {
        setFetching(false);
      }
    }
  };

  const handleCancel = () => {
    form.reset();
    props.editArticle(false, null);
  };

  const handleSubmit = async (values: ArticleFormData) => {
    if (props.id) {
      try {
        const updatedValues = {
          ...values,
          body: rteContent,
          category_id: values.category.id,
        };
        if (uploadedImage?.file) {
          await uploadArticleBodyImage(
            `/articles/${props.id}/image/1.png`,
            uploadedImage.file || null
          );
        }
        await updateArticle(props.id, updatedValues);
        props.editArticle(false, null);
      } catch (error) {
        console.error("Error updating article:", error);
      }
    } else {
      const createdValues: CreateArticleData = {
        ...values,
        body: rteContent,
        category_id: values.category.id,
      };
      await createArticle(createdValues);
      props.editArticle(false, null);
    }
  };

  const handleCategoryClick = (category: Category) => {
    form.setFieldValue("category", {
      ...form.values.category,
      name: category.name,
      id: category.id,
    });
    setSearchTerm(category.name);
    setTimeout(() => {
      setMenuVisible(false);
    }, 100);
  };

  const handleInputFocus = () => {
    setInitialFetch(true);
    setMenuVisible(true);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    setSearchTerm(value);
    form.setFieldValue("category", { ...form.values.category, name: value });

    if (value === "") {
      setMenuVisible(false);
    } else {
      setMenuVisible(true);
    }
  };

  const handleDrop = (file: File[]) => {
    const newImages = {
      file: file[0],
      url: URL.createObjectURL(file[0])
    }
    setUploadedImage(newImages);
  };

  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }

  return (
    <>
      <Button
        className="back-button"
        leftSection={<ChevronLeft size={20} />}
        onClick={handleCancel}
        style={{
          backgroundColor: "#ffffff",
          color: "#25262B",
          fontSize: "16px",
          padding: 0,
          border: "#ffffff",
          transition: "background-color 0.3s",
          marginBottom: "2%",
        }}
      >
        Назад
      </Button>

      <div className="flex flex-col gap-6">
        <Group
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2%",
          }}
        >
          <h1 className="basefont-h1">{props.title}</h1>
          <CustomButton
            onClick={() => form.onSubmit(handleSubmit)()}
            style={{
              color: "#ffffff",
              backgroundColor: "#0560BE",
              width: "200px",
            }}
          >
            Сохранить
          </CustomButton>
        </Group>

        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 space-y-4">
            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <TextInput
                label="Название статьи"
                placeholder="Введите название"
                {...form.getInputProps("title")}
                styles={{
                  input: {
                    borderRadius: "12px",
                    height: "44px",
                    marginBottom: "12px",
                  },
                }}
              />
              <TextInput
                label="Подзаголовок"
                placeholder="Введите подзаголовок"
                {...form.getInputProps("subtitle")}
                styles={{
                  input: {
                    borderRadius: "12px",
                    height: "44px",
                    marginBottom: "12px",
                  },
                }}
              />
              <TextInput
                label="Подборка товаров"
                placeholder="Введите id подборки товаров"
                {...form.getInputProps("collection_id")}
                styles={{
                  input: {
                    borderRadius: "12px",
                    height: "44px",
                    marginBottom: "12px",
                  },
                }}
              />
              {!uploadedImage && (
                <>
                  <span className="basefont-t6">Изображение (1:1)</span>
                  <Dropzone
                    onDrop={handleDrop}
                    accept={IMAGE_MIME_TYPE}
                    className="dropzone"
                    maxFiles={1}
                  >
                    <Text ta="center">
                      Upload image <br /> png, jpg
                    </Text>
                  </Dropzone>
                </>
              )}
              {uploadedImage?.url &&
                <div className="flex items-center w-full">
                  <img
                    src={uploadedImage.url}
                    alt={uploadedImage.url}
                    className="w-full h-[100px] object-contain rounded-xl"
                  />
                  <IconTrash
                    size={16}
                    color="#0560BE"
                    cursor='pointer'
                    onClick={() => handleDeleteImage()}
                  />
                </div>}

              <TextInput
                label="Категория"
                placeholder="Введите категорию"
                value={searchTerm ?? form.values.category.name}
                onFocus={handleInputFocus}
                onClick={handleInputFocus}
                onBlur={(event) => {
                  if (
                    !event.currentTarget.contains(event.relatedTarget as Node)
                  ) {
                    setTimeout(() => {
                      setMenuVisible(false);
                    }, 300);
                  }
                }}
                onChange={handleInputChange}
                styles={{
                  input: {
                    borderRadius: "12px",
                    height: "44px",
                    marginBottom: "12px",
                  },
                }}
              />
              {menuVisible && (
                <div
                  style={{
                    maxHeight: "120px",
                    overflowY: "auto",
                    marginTop: "-2.5%",
                    borderRadius: "12px",
                    border: "1px solid #ccc",
                    padding: "8px",
                    position: "relative",
                  }}
                  onScroll={handleScroll}
                >
                  {categories.map((category) => (
                    <div
                      key={category.id}
                      onClick={() => handleCategoryClick(category)}
                      style={{
                        padding: "8px",
                        cursor: "pointer",
                        borderRadius: "8px",
                        paddingBottom: "12px",
                        backgroundColor:
                          form.values.category.id === category.id
                            ? "#f0f0f0"
                            : "transparent",
                      }}
                    >
                      {category.name}
                    </div>
                  ))}
                  {fetching && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Loader size="sm" />
                    </div>
                  )}
                </div>
              )}
              <RTE content={rteContent} setContent={setRteContent} uploadImageUrl="https://new.999111.ru/admin/api/v1/articles/image/1.png" />
            </Card>
          </div>

          <div className="space-y-4">
            <Card
              shadow="sm"
              padding="lg"
              radius="xl"
              withBorder
              className="rounded-3xl gap-4"
            >
              <h2 className="basefont-h2">Статус статьи</h2>
              <Select
                label="Статус активности"
                placeholder="Выберите статус"
                data={["Активен", "Не активен"]}
                value={form.values.active ? "Активен" : "Не активен"}
                onChange={(value) =>
                  form.setFieldValue("active", value === "Активен")
                }
                styles={{
                  input: {
                    borderRadius: "12px",
                  },
                }}
              />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditArticle;
