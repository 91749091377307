import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  TextInput,
  Loader,
} from "@mantine/core";
import { Search } from "tabler-icons-react";
import { getAvailableProducts, getProducts } from "../../lib/api/products";
import {
  AvailableProductCollectionResponse,
  ProductCollection,
  ProductCollectionResponse,
} from "../../lib/api/types/products";
import Paginator from "../../shared/paginator";

type Props = {
  headers: string[];
  productsIds: string[] | null;
  type: string;
  handleProduct: (productId: string, type: string) => void;
};

const ProductsTable: React.FC<Props> = ({
  headers,
  productsIds,
  type,
  handleProduct,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");

  const [data, setData] = useState<ProductCollection[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [activePage, setPage] = useState(1);
  const [total, setTotal] = useState<number>(0);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const fetchData = async () => {
    setLoading(true);
    if (type === "selected") {
      const offset = (activePage - 1) * selectedRowsNumber;
      const products: ProductCollectionResponse = await getProducts({
        product_ids: productsIds,
        offset: offset,
        limit: selectedRowsNumber,
      });
      setData(products.products);
      setTotal(products.total);
      setLoading(false);
    } else if (type === "select") {
      const offset = (activePage - 1) * selectedRowsNumber;
      const products: AvailableProductCollectionResponse =
        await getAvailableProducts({
          search: searchValue,
          excluded_product_ids: productsIds,
          offset: offset,
          limit: selectedRowsNumber,
        });
      setData(products.products);
      setTotal(products.total);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, activePage, selectedRows, productsIds]);

  const handleResetFilters = () => {
    setSearchValue("");
    setPage(1);
  };

  const rows = data?.map((item, index) => (
    <Table.Tr key={index}>
      <Table.Td>{item.item_id}</Table.Td>
      <Table.Td>{item.name}</Table.Td>
      <Table.Td>
        {item.characteristics
          .map(
            (characteristic) =>
              `${characteristic.name}: ${characteristic.value}`
          )
          .join(", ")}
      </Table.Td>
      <Table.Td>
        {type === "select" ? (
          <Button
            size="md"
            radius="sm"
            style={{
              backgroundColor: "#0560BE",
              color: "#ffffff",
              border: "1px solid #0560BE",
              borderRadius: 12,
              fontSize: 15,
              textAlign: "center",
              width: "120px",
              cursor: "pointer",
            }}
            onClick={() => handleProduct(item.item_id, "select")}
          >
            Добавить
          </Button>
        ) : (
          <Button
            size="md"
            radius="sm"
            style={{
              flex: 0.3,
              backgroundColor: "white",
              color: "#25262B",
              border: "1px solid #0560BE",
              borderRadius: 12,
              fontSize: 15,
              width: "120px",
              cursor: "pointer",
            }}
            onClick={() => handleProduct(item.item_id, "selected")}
          >
            Удалить
          </Button>
        )}
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <div className="w-full">
      {type === "select" && (
        <div style={{ display: "flex", marginBottom: "1rem", gap: "1rem" }}>
          <TextInput
            style={{ flex: 1 }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.currentTarget.value)}
            placeholder="Поиск по Наименование товара на сайте или по названию"
            leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
            size="md"
            radius="md"
          />
          <Button
            onClick={handleResetFilters}
            size="md"
            radius="sm"
            style={{
              backgroundColor: "white",
              color: "#25262B",
              borderRadius: 12,
              fontSize: 15,
              width: "120px",
            }}
          >
            Сбросить
          </Button>
        </div>
      )}

      {loading ? (
        <div className="h-screen flex items-center justify-center">
          <Loader size="lg" />
        </div>
      ) : (
        <>
          <Table withTableBorder verticalSpacing="md">
            <Table.Thead
              style={{ color: "#1E2134", backgroundColor: "#FAFAFA" }}
            >
              <Table.Tr>
                {headers.map((header, index) => (
                  <Table.Th key={index}>{header}</Table.Th>
                ))}
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>

          <Paginator
            activePage={activePage}
            total={total}
            selectedRowsNumber={selectedRowsNumber}
            setPage={setPage}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />
        </>
      )}
    </div>
  );
};

export default ProductsTable;
