import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import { fetchCarts } from "../../lib/api/carts";
import { CartResponse, Cart } from "../../lib/api/types/carts";
import { formatDate } from "date-fns";
import { formatRuble } from "../../lib/utils";
import Paginator from "../../shared/paginator";

const AbandonedCartsTable = ({
  onRowClick,
  openedSideBar,
}: {
  onRowClick: (cart: Cart) => void;
  openedSideBar: boolean;
}) => {
  const headers = [
    "ID клиента",
    "Клиент",
    "Номер телефона",
    "Дата создания",
    "E-mail",
    "Сумма корзины",
    "Кол-во поз-й",
  ];
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");

  const [data, setData] = useState<Cart[]>([]);
  const [loading, setLoading] = useState(false);

  const [total, setTotal] = useState<number>(25);

  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const offset = (activePage - 1) * selectedRowsNumber;
      const params = {
        search: searchId,
        modified_from: dateValue[0]?.toISOString() || null,
        modified_to: dateValue[1]?.toISOString() || null,
        offset: offset,
        limit: selectedRowsNumber,
      };
      const response: CartResponse = await fetchCarts(params);
      setData(response.carts);
      setTotal(response.total);
      setLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, dateValue, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
  };

  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 3.5, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        styles={(theme) => ({
          input: {
            fontSize: openedSideBar ? `calc(100% - 5px)` :'' ,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        })}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        type="range"
        label="Дата последнего изменения"
        placeholder="Любая"
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={data.map((cart) => ({
            "ID клиента": cart.user.id,
            Клиент:
              cart.user.first_name +
              " " +
              cart.user.last_name +
              " " +
              cart.user.father_name,
            "Номер телефона": cart.user.phone,
            "Дата создания": formatDate(cart.modified, "dd.MM.yyyy"),
            "E-mail": cart.user.email,
            "Сумма корзины": formatRuble(cart.price_total),
            "Кол-во поз-й": cart.positions_count,
          }))}
          onRowClick={onRowClick}
          loading={loading}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default AbandonedCartsTable;
