import {
  Button as MantineButton,
  Group,
  Select,
  TextInput,
  Card,
  Text,
  Loader
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CalendarEvent, ChevronLeft } from "tabler-icons-react";
import { RTE } from "../../shared/richTextEditor";
import { Button } from "../../shared/button";
import { News, NewsFormData } from "../../lib/api/types/news";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconTrash } from "@tabler/icons-react";
import { createNews, getNewsById, updateNews, uploadNewsBodyImage } from "../../lib/api/news";
import { DatePickerInput } from "@mantine/dates";


interface PromotionFormProps {
  title: string;
  editNews: (value: boolean, review: News | null) => void;
  id: string | null;
}
interface UploadedImage {
  file?: File;
  url: string | null;
}

const PromotionForm: React.FC<PromotionFormProps> = ({
  title,
  editNews,
  id,
}) => {
  const form = useForm<NewsFormData>({
    initialValues: {
      active: false,
      type: "",
      collection_id: "",
      image_url: "",
      subtitle: "",
      title: "",
      body: "",
      date: "",
    },
    validate: {
      active: (value) => (typeof value === "boolean" ? null : "Это поле обязательно"),
      type: (value) => (value ? null : "Тип обязателен"),
      collection_id: (value) => (value ? null : "ID коллекции обязателен"),
      subtitle: (value) => (value ? null : "Подзаголовок обязателен"),
      title: (value) => (value ? null : "Заголовок обязателен"),
    },
  });
  const [createdDate, setCreatedDate] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [uploadedImage, setUploadedImage] = useState<UploadedImage | null>(null);
  const [loading, setLoading] = useState(true);
  const [rteContent, setRteContent] = useState('');

  useEffect(() => {
    const fetchNewsDetail = async () => {
      if (id) {
        try {
          const news = await getNewsById(id);
          form.setValues({
            type: news.type === 'news' ? 'Акция' : 'Новость',
            active: news.active ? true : false,
            collection_id: news.collection_id,
            title: news.title,
            subtitle: news.subtitle,
            image_url: news.image_url,
            body: news.body
          });
          setCreatedDate([
            new Date(news.start_date),
            new Date(news.end_date),
          ]);
          if (news.image_url) { setUploadedImage({ url: news.image_url }) }
          setRteContent(news.body);
          setLoading(false);
        } catch (error) {
          console.error("Error fetching article:", error);
        }
      } else {
        setLoading(false);
        form.reset();
      }
    };
    fetchNewsDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  const handleFormSubmit = async (values: NewsFormData) => {
    if (id) {
      try {
        const updatedValues = {
          ...values,
          type: values.type === 'Акция' ? 'news' : 'promo',
          body: rteContent,
          start_date: createdDate[0]?.toISOString().slice(0, -5) + "Z",
          end_date: createdDate[1]?.toISOString().slice(0, -5) + "Z",
        };
        if (uploadedImage?.file) {
          await uploadNewsBodyImage(`/news/${id}/image/1.png`, uploadedImage.file);
        }
        await updateNews(id, updatedValues);
        editNews(false, null);
      } catch (error) {
        console.error("Error updating article:", error);
      }
    } else {
      const createdValues = {
        ...values,
        type: values.type === 'Акция' ? 'news' : 'promo',
        body: rteContent,
        start_date: createdDate[0]?.toISOString().slice(0, -5) + "Z",
        end_date: createdDate[1]?.toISOString().slice(0, -5) + "Z",
      };
      await createNews(createdValues);
      editNews(false, null);
    }

  };

  const handleCancel = () => {
    form.reset();
    editNews(false, null);
  };
  const handleDrop = (file: File[]) => {
    if (file.length > 0) {
      const newImages = {
        file: file[0],
        url: URL.createObjectURL(file[0]),
      };
      setUploadedImage(newImages);
    }
  };


  const handleDeleteImage = () => {
    setUploadedImage(null);
  };

  const getStatusLabel = (status: boolean) =>
    status ? "Активен" : "Не Активен";

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Loader size="lg" />
      </div>
    );
  }
  return (
    <div>
      <MantineButton
        leftSection={<ChevronLeft size={20} />}
        onClick={handleCancel}
        style={{
          backgroundColor: "#ffffff",
          color: "#25262B",
          fontSize: "16px",
          padding: 0,
          border: "#ffffff",
          transition: "background-color 0.3s",
          marginBottom: "2%",
        }}
      >
        Назад
      </MantineButton>
      <form onSubmit={form.onSubmit(handleFormSubmit)}>
        <div className="flex flex-col gap-6">
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "2%",
            }}
          >
            <h1 className="basefont-h1">{title}</h1>
            {title === "Редактировать акцию или новость" ? (
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  variant="outline"
                  onClick={handleCancel}
                  style={{
                    width: "200px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Отменить
                </Button>
                <Button
                  type="submit"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "200px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Сохранить
                </Button>
              </div>
            ) : (
              <Button
                type="submit"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#0560BE",
                  width: "200px",
                  height: "48px",
                  padding: "8px, 20px, 8px, 20px",
                  marginLeft: "10px",
                }}
              >
                Сохранить
              </Button>
            )}
          </Group>

          <div className="grid grid-cols-3 gap-4">
            <div className="col-span-2 space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <Select
                  label="Тег"
                  placeholder="Выберите тег"
                  data={["Акция", "Новость"]}
                  {...form.getInputProps("type")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                <TextInput
                  label="Название"
                  placeholder="Введите название"
                  {...form.getInputProps("title")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                <TextInput
                  label="Подзаголовок"
                  placeholder="Введите подзаголовок"
                  {...form.getInputProps("subtitle")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                <TextInput
                  label="Подборка товаров"
                  placeholder="Введите id подборки товаров"
                  {...form.getInputProps("collection_id")}
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
                {!uploadedImage && (
                  <>
                    <span className="basefont-t6">Изображение (1:1)</span>
                    <Dropzone
                      onDrop={handleDrop}
                      accept={IMAGE_MIME_TYPE}
                      className="dropzone"
                      maxFiles={1}
                    >
                      <Text ta="center">
                        Upload image <br /> png, jpg
                      </Text>
                    </Dropzone>
                  </>
                )}
                {uploadedImage?.url &&
                  <div className="flex items-center w-full">
                    <img
                      src={uploadedImage.url}
                      alt={uploadedImage.url}
                      className="w-full h-[100px] object-contain rounded-xl"
                    />
                    <IconTrash
                      size={16}
                      color="#0560BE"
                      cursor='pointer'
                      onClick={() => handleDeleteImage()}
                    />
                  </div>}

                <DatePickerInput
                  {...form.getInputProps("date")}
                  style={{ flex: 1 }}
                  valueFormat="DD.MM.YYYY"
                  rightSection={
                    <CalendarEvent
                      size={20}
                      color="#506176"
                      strokeWidth={1.5}
                    />
                  }
                  label="Дата акции"
                  placeholder="Выберите дату действия акции"
                  type="range"
                  value={createdDate}

                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                  onChange={(newValue) => {
                    setCreatedDate(newValue);
                  }}

                />

                <RTE content={rteContent} setContent={setRteContent} uploadImageUrl="https://new.999111.ru/admin/api/v1/news/image/1.png" />
                <Button
                  type="submit"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#0560BE",
                    width: "360px",
                    height: "48px",
                    padding: "8px, 20px, 8px, 20px",
                    marginLeft: "10px",
                  }}
                >
                  Сохранить
                </Button>
              </Card>
            </div>
            <div className="space-y-4">
              <Card
                shadow="sm"
                padding="lg"
                radius="xl"
                withBorder
                className="rounded-3xl gap-4"
              >
                <h2 className="basefont-h2">Статус </h2>
                <Select
                  label="Статус активности"
                  placeholder="Выберите статус"
                  data={["Активен", "Не Активен"]}
                  value={getStatusLabel(form.values.active)}
                  onChange={(value) =>
                    form.setFieldValue("active", value === "Активен")
                  }
                  styles={{
                    input: {
                      borderRadius: "12px",
                      height: "44px",
                      marginBottom: "12px",
                    },
                  }}
                />
              </Card>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PromotionForm;
