import { useState } from "react";
import AbandonedCartDetails from "../components/abandonedCart/AbandonedCartDetails";
import AbandonedCartsTable from "../components/abandonedCart/AbandonedCartsTable";

const AbandonedCarts = ({ openedSideBar }: { openedSideBar: boolean }) => {
  const [selectedCartId, setSelectedCartId] = useState<string | null>(null);

  const handleRowClick = (cart: any) => {
    setSelectedCartId(cart["ID клиента"]);
  };

  const closeDetails = () => {
    setSelectedCartId(null);
  };

  return (
    <div>
      {selectedCartId ? (
        <AbandonedCartDetails
          cartId={selectedCartId}
          closeDetails={closeDetails}
        />
      ) : (
        <div>
          <h1 className="basefont-h1">Брошенные корзины</h1>
          <AbandonedCartsTable
            onRowClick={handleRowClick}
            openedSideBar={openedSideBar}
          />
        </div>
      )}
    </div>
  );
};

export default AbandonedCarts;
