import axios from "./base";
import {
  AvailableProductCollectionResponse,
  ProductCollectionResponse,
} from "./types/products";

export const getProducts = async (params: {
  product_ids: string[] | null;
  offset: number;
  limit: number;
}): Promise<ProductCollectionResponse> => {
  const response = await axios.post(`/catalog/query-products`, params);
  return response.data;
};

export const getAvailableProducts = async (params: {
  search: string;
  excluded_product_ids: string[] | null;
  offset: number;
  limit: number;
}): Promise<AvailableProductCollectionResponse> => {
  const response = await axios.post(
    `/catalog/query-available-products`,
    params
  );
  return response.data;
};
