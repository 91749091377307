import {
  Button,
  MantineProvider,
  TextInput,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { MultiSelectCheckbox } from "../../shared/MultiSelectCheckbox";
import { CalendarEvent, Search } from "tabler-icons-react";
import { DatePickerInput } from "@mantine/dates";
import TablePattern from "../table-pattern";
import Paginator from "../../shared/paginator";
import { Employee, EmployeeQueryParams } from "../../lib/api/types/employee";
import { queryAdmins } from "../../lib/api/employee";

interface EmployeeTableProps {
  openModal: () => void;
  setModalTitle: (title: string) => void;
  updateData: boolean;
  setID: (id: string) => void;

}

const roleTranslations: { [key: string]: string } = {
  "Aдминистратор": "super_admin",
  "Mенеджер ИМ": "manger",
  "Kонтент-менеджер": "content_manger"
};
const roleMap: Record<string, string> = {
  super_admin: 'Aдминистратор',
  manager: 'Mенеджер ИМ',
  content_manager: 'Kонтент-менеджер',
};
const EmployeeTable: React.FC<EmployeeTableProps> = (props) => {
  const [dateValue, setDateValue] = useState<[Date | null, Date | null]>([
    null,
    null,
  ]);
  const [searchId, setSearchId] = useState<string>("");
  const [selectedStatus, setSelectedStatus] = useState<string[] | null>(null);
  const [selectedPosition, setSelectedPosition] = useState<string[] | null>(null);
  const [data, setData] = useState<Employee[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [total, setTotal] = useState<number>(25);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<string>("8");
  const selectedRowsNumber = Number(selectedRows);
  const [activePage, setPage] = useState(1);

  const fetchAdmins = async (params: EmployeeQueryParams) => {
    setLoading(true);
    const response = await queryAdmins(params);
    setLoading(false);
    setData(response.admins);
    setTotal(response.total);
  };

  useEffect(() => {
    const offset = (activePage - 1) * selectedRowsNumber;
    const activeStatus =
      selectedStatus?.includes("Все") || null
        ? [false, true]
        : selectedStatus?.map((v) => v === "Активен");

    const translatedRole = selectedPosition
      ? selectedPosition.map((method) => roleTranslations[method] || method)
      : null;

    const params: EmployeeQueryParams = {
      search: searchId,
      active: activeStatus,
      offset: offset,
      limit: selectedRowsNumber,
      roles: translatedRole,
      created_from: dateValue[0]?.toISOString() || null,
      created_to: dateValue[1]?.toISOString() || null,
    };
    fetchAdmins(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchId, selectedStatus, selectedPosition, dateValue, props.updateData, activePage, selectedRows]);

  const handleResetFilters = () => {
    setDateValue([null, null]);
    setSearchId("");
    setSelectedStatus(null);
    setSelectedPosition(null);
    const params: EmployeeQueryParams = {
      search: "",
      active: null,
      roles: null,
      created_from: null,
      created_to: null,
      offset: 0,
      limit: selectedRowsNumber,
    };
    fetchAdmins(params)
  };


  const handleMultiChooseSelect = (value: string[], choices: string[], setValue: (data: any) => void, data: string[] | null) => {
    if (value.includes("Все")) {
      if (!data?.includes("Все")) {
        setValue(choices);
      } else if (data?.includes("Все")) {
        const newStatus = value.filter((v) => v !== "Все");
        setValue(newStatus);
      }
    } else {
      if (data?.includes("Все")) {
        setValue(null);
      } else if (value.length === 0) {
        setValue(null);
      } else {
        setValue(value);
      }
    }
  };


  const filtersPattern = (
    <>
      <TextInput
        style={{ flex: 1, marginTop: "25px" }}
        placeholder="Поиск"
        leftSection={<Search size={14} color="#506176" strokeWidth={3} />}
        value={searchId}
        size="md"
        radius="md"
        onChange={(event) => {
          setSearchId(event.currentTarget.value);
        }}
      />
      <DatePickerInput
        style={{ flex: 1 }}
        valueFormat="DD.MM.YYYY"
        minDate={new Date(0)}
        maxDate={new Date()}
        type="range"
        label="Дата создания"
        placeholder="Любая"
        rightSection={
          <CalendarEvent size={20} color="#506176" strokeWidth={1.5} />
        }
        size="md"
        radius="md"
        value={dateValue}
        onChange={(newValue) => {
          setDateValue(newValue);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Должность"
        data={["Все", "Aдминистратор", "Mенеджер ИМ", "Kонтент-менеджер"]}
        value={selectedPosition ? selectedPosition : []}
        onChange={(value: any) => {
          handleMultiChooseSelect(value, ["Все", "Aдминистратор", "Mенеджер ИМ", "Kонтент-менеджер"], setSelectedPosition, selectedPosition);
        }}
      />
      <MultiSelectCheckbox
        style={{ flex: 1 }}
        placeholder="Все"
        label="Статус активности"
        data={["Все", "Активен", "Не Активен"]}
        value={selectedStatus ? selectedStatus : []}
        onChange={(value: any) => {
          handleMultiChooseSelect(value, ["Все", "Активен", "Не Активен"], setSelectedStatus, selectedStatus);
        }}
      />

      <Button
        onClick={handleResetFilters}
        size="md"
        radius="sm"
        style={{
          flex: 0.3,
          backgroundColor: "white",
          color: "#25262B",
          borderRadius: 12,
          fontSize: 15,
          width: "120px",
          marginTop: "25px",
        }}
      >
        Сбросить
      </Button>
    </>
  );

  const headers = [
    "ID сотрудника",
    "Дата создания",
    "ФИО",
    "Должность",
    "Почта",
    "Статус активности",
  ];



  const getFieldToStyle = () => "status";

  const getStatusStyle = (status: string) => {
    switch (status) {
      case "Активен":
        return {
          backgroundColor: "#DEF7F0",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      case "Не Активен":
        return {
          backgroundColor: "#FDE3E3",
          borderRadius: 16,
          padding: "8px 16px 8px 16px",
          fontWeight: 500,
        };
      default:
        return {};
    }
  };

  const handleRowClick = (employee: Employee) => {
    props.setID(employee.id);
    props.openModal();
    props.setModalTitle("Редактировать данные");
  };
  const filteredData = data.map((employee) => ({
    id: employee.id,
    created: new Date(employee.created).toLocaleDateString("ru-RU"),
    fullName: `${employee.first_name} ${employee.last_name} ${employee.father_name}`,
    role: roleMap[employee.role] || employee.role,
    email: employee.email,
    status: employee.active ? "Активен" : "Не Активен",
  }));

  return (
    <MantineProvider>
      <div>
        <TablePattern
          filters={filtersPattern}
          headers={headers}
          data={filteredData}
          getFieldToStyle={getFieldToStyle}
          getStyle={getStatusStyle}
          onRowClick={handleRowClick}
          customDataType="employee"
          loading={loading}
          customData={data}
          activePage={activePage}
          selectedRow={selectedRows}
        />
        <Paginator
          activePage={activePage}
          total={total}
          selectedRowsNumber={selectedRowsNumber}
          setPage={setPage}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      </div>
    </MantineProvider>
  );
};

export default EmployeeTable;
