import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}


export function formatRuble(amount: number): string {
  let amountStr = amount.toString();
  amountStr = amountStr.replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  amountStr += " ₽";

  return amountStr;
}

export const formatPhone = (str: string) => {
  return str.replace(/[^0-9]/g, "");
};
