import { useForm } from "@mantine/form";
import { Button, Select, Group, TextInput, Loader } from "@mantine/core";
import { ChevronLeft } from "tabler-icons-react";
import { useState, useEffect } from "react";
import { Button as CustomButton } from "../../shared/button";
import { StoreFormValues, Store } from "../../lib/api/types/store";
import { addStore, updateStore } from "../../lib/api/stores";
import axios from "../../lib/api/base";
import WorkingHours from "./working-hour";


interface StoreFormProps {
  store: Store | null;
  closeModal: () => void;
}
const PHONE_REGEX = /^[0-9]+$/;
const DEFAULT_PREFIX = "+7";

const allDays: { value: number, label: string }[] = [
  { value: 0, label: 'Пн' },
  { value: 1, label: 'Вт' },
  { value: 2, label: 'Ср' },
  { value: 3, label: 'Чт' },
  { value: 4, label: 'Пт' },
  { value: 5, label: 'Сб' },
  { value: 6, label: 'Вс' },
];

const StoreForm: React.FC<StoreFormProps> = ({ store, closeModal }) => {
  const form = useForm<StoreFormValues>({
    initialValues: {
      id: store?.store_id || '',
      internal_name: store?.internal_name || '',
      creationDate: store?.added || '',
      city: store?.city || '',
      name: store?.name || '',
      address: store?.address || '',
      phone: store?.phone_number || '',
      coordinates: store?.coordinates || '',
      status: store?.active ? 'Активен' : 'Не активен',
      hours:
        store?.open_times.map((time) => ({
          days: time.days,
          from: time.from,
          to: time.to,
        })) || [{ days: [], from: '', to: '' }],
      type: store?.type || 'store',
    },
    validate: {
      internal_name: (value) => (value ? null : 'Внутреннее название обязательно'),
      city: (value) => (value ? null : 'Город обязателен'),
      name: (value) => (value ? null : 'Название обязательно'),
      address: (value) => (value ? null : 'Адрес обязателен'),
      phone: (value) =>
        !PHONE_REGEX.test(value.replace("+", ""))
          ? "Номер телефона может содержать только цифры"
          : null,
      coordinates: (value) => {
        const isValidCoordinates = /^-?\d+(\.\d+)?,\s{1}-?\d+(\.\d+)?$/.test(value);
        return isValidCoordinates ? null : 'Координаты должны быть числами в формате "x, y" (например, 55.5, 55.3)';
      },
      status: (value) => (value ? null : 'Статус обязателен'),
      type: (value) => (value ? null : 'Тип обязателен'),
    },
  },);

  const formatPhoneNumber = (value: string) => {
    const digitsOnly = value.replace(/\D/g, "");
    if (!digitsOnly.startsWith("7")) {
      return `${DEFAULT_PREFIX}${digitsOnly}`;
    }
    return `+${digitsOnly}`;
  };

  const [storeOptions, setStoreOptions] = useState<{ value: string; label: string }[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotAddedStores = async () => {
      try {
        if (!store) {
          const response = await axios.post('/stores/query-not-added', { search: '' });
          const stores = response.data.stores.map(
            (store: { store_id: string; internal_name: string }) => ({
              value: store.store_id,
              label: store.internal_name,
            })
          );
          setStoreOptions(stores);
        } else {
          setStoreOptions([{ value: store.store_id, label: store.internal_name }]);
        }
      } catch (error) {
        console.error('Failed to fetch not-added stores:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotAddedStores();
  }, [store]);

  const handleWorkingHoursChange = (newWorkingHours: { days: number[]; from: string; to: string }[]) => {
    form.setFieldValue('hours', newWorkingHours);
  };

  const handleSubmit = async (values: StoreFormValues) => {
    if (!store) {
      const selectedStore = storeOptions?.find(
        (option) => option.label === values.internal_name
      );
      if (selectedStore) {
        values.id = selectedStore.value;
        values.internal_name = selectedStore.label;
      }
    }

    const storeData: any = {
      store_id: values.id,
      name: values.name,
      active: values.status === 'Активен',
      city: values.city || '',
      address: values.address || '',
      coordinates: values.coordinates || '',
      phone_number: values.phone.slice(0),
      open_times: values.hours,
      type: values.type,
    };
    try {
      if (store?.store_id) {
        await updateStore(storeData);
      } else {
        await addStore(storeData);
      }
      closeModal();
    } catch (error) {
      console.error('Failed to save store:', error);
    }
  };

  const fieldStyles = {
    input: {
      borderRadius: '12px',
      height: '44px',
      marginBottom: '12px',
    },
  };

  return (
    <>
      <Button
        leftSection={<ChevronLeft size={20} />}
        onClick={closeModal}
        style={{
          backgroundColor: '#ffffff',
          color: '#25262B',
          fontSize: '16px',
          padding: 0,
          border: '#ffffff',
          transition: 'background-color 0.3s',
          marginBottom: '2%',
        }}
      >
        Назад
      </Button>
      <div className="flex flex-col gap-6">
        <Group
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '2%',
          }}
        >
          <h1 className="basefont-h1">
            {store ? 'Редактирование магазина' : 'Создание магазина'}
          </h1>
          {store ? (
            <Group>
              <CustomButton
                variant="outline"
                onClick={closeModal}
                style={{
                  backgroundColor: '#ffffff',
                  color: '#1E2134',
                  border: ' white',
                }}
              >
                Отменить
              </CustomButton>
              <CustomButton
                type="submit"
                style={{
                  color: '#ffffff',
                  backgroundColor: '#0560BE',
                  width: '200px',
                }}
                onClick={() => form.onSubmit(handleSubmit)()}
              >
                Сохранить
              </CustomButton>
            </Group>
          ) : (
            <CustomButton
              type="submit"
              style={{
                color: '#ffffff',
                backgroundColor: '#0560BE',
                width: '200px',
              }}
              onClick={() => form.onSubmit(handleSubmit)()}
            >
              Добавить
            </CustomButton>
          )}
        </Group>

        {loading ? (
          <Loader className="mx-auto" />
        ) : (
          <>
            <div className="w-1/2">
              <Select
                label="Магазин"
                placeholder="Выберите Магазин"
                data={storeOptions}
                value={form.values.id}
                onChange={(value) => {
                  const selectedOption = storeOptions.find(
                    (option) => option.value === value
                  );
                  if (selectedOption) {
                    form.setFieldValue('internal_name', selectedOption.label);
                    form.setFieldValue('id', selectedOption.value);
                  }
                }}
                styles={fieldStyles}
              />
              <Select
                label="Тип"
                placeholder="Выберите тип"
                data={[
                  { value: 'store', label: 'Магазин' },
                  { value: 'warehouse', label: 'Склад' },
                ]}
                value={form.values.type}
                onChange={(value) => form.setFieldValue('type', value as any)}
                styles={fieldStyles}
              />
              <TextInput
                label="Город"
                placeholder="Введите город"
                {...form.getInputProps('city')}
                styles={fieldStyles}
              />
              <TextInput
                label="Название"
                placeholder="Введите название"
                {...form.getInputProps('name')}
                styles={fieldStyles}
              />
              <TextInput
                label="Адрес"
                placeholder="Введите адрес"
                {...form.getInputProps('address')}
                styles={fieldStyles}
              />
              <TextInput
                label="Номер телефона"
                placeholder="Введите номер телефона"
                {...form.getInputProps('phone')}
                onChange={(event) => {
                  const value = event.currentTarget.value;
                  if ((PHONE_REGEX.test(value.replace("+", "")) || value === "") &&
                    value.length <= 12) {
                    form.setFieldValue("phone", formatPhoneNumber(value));
                  }
                }}
                error={form.errors.phone}
                styles={fieldStyles}
              />
              <TextInput
                label="Координаты"
                placeholder="Введите координаты (широта, долгота)"
                {...form.getInputProps('coordinates')}
                styles={fieldStyles}
              />
              <Select
                label="Статус активности"
                placeholder="Выберите статус"
                data={['Активен', 'Не активен']}
                value={form.values.status}
                onChange={(value) => form.setFieldValue('status', value as string)}
                styles={fieldStyles}
              />
            </div>

            <WorkingHours
              fieldStyles={fieldStyles}
              onChange={handleWorkingHoursChange}
              allDays={allDays}
            />
          </>
        )}
      </div>
    </>
  );
};

export default StoreForm;
